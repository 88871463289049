<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-toolbar-title class="ml-2 mt-2"
            >Gerenciamento de Campanhas</v-toolbar-title
          >
        </v-col>
        <v-col
          class="colunaHeader"
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          align="end"
        >
          <v-btn class="ml-2 my-1" color="primary" @click="dialog = true">
            <v-icon left>mdi-plus-thick</v-icon> Cadastrar Nova Campanha
          </v-btn>

          <v-btn class="ml-2 my-1" color="primary" @click="exportar">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.dates"
              :items="allDates()"
              label="Mês/Ano"
              multiple
              @change="filter()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.campanha"
              :items="items"
              :search-input.sync="search"
              hide-no-data
              hide-details
              label="Nome da campanha Admin"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.campanhaapp"
              :items="items4"
              :search-input.sync="search4"
              hide-no-data
              hide-details
              label="Nome da campanha APP"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3">
            <v-autocomplete
              outlined
              dense
              clearable
              class="text-capitalize"
              :items="['raia', 'drogasil']"
              label="Bandeira"
              v-model="filtro.bandeira"
              @change="filter()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-text-field
              label="Data inicial"
              v-model="filtro.data_ini"
              @change="
                filtro.data_ini_prev = formatDate(filtro.data_ini);
                filter();
              "
              outlined
              dense
              type="date"
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-text-field
              label="Data Final"
              v-model="filtro.data_fim"
              @change="
                filtro.data_fim_prev = formatDate(filtro.data_fim);
                filter();
              "
              outlined
              dense
              type="date"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="5" lg="3" xl="3" start>
          <v-btn class="ml-2 mb-1" color="primary" @click="filtro = {}">
            <v-icon left>mdi-filter-off</v-icon> Limpar
          </v-btn>
        </v-col>
      </v-card>

      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-data-table
          :headers="headers"
          :items="campanhasFiltradas"
          item-key="id"
          multi-sort
          :items-per-page="itemsPerPage"
          class="elevation-0"
          v-model="campanhasFiltradas"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          @page-count="changePages"
          @current-items="changeCurrentItens"
        >
          <template v-slot:[`item.mesAno`]="{ item }">
            {{ item.mes.toString().padStart(2, "0") }}/{{ item.ano }}
          </template>

          <template v-slot:[`item.periodo`]="{ item }">
            {{ item.data_ini.string }} à {{ item.data_fim.string }}
          </template>

          <template v-slot:[`item.pdf`]="{ item }">
            <div v-if="item.pdf">
              <v-icon @click="downloadItem(item.pdf, `${item.nome}.pdf`)"
                >mdi-file-download</v-icon
              >
            </div>
          </template>

          <template v-slot:[`item.peca`]="{ item }">
            {{ item.id_peca.length }}
          </template>

          <template v-slot:[`item.filias`]="{ item }">
            {{ item.id_loja.length }}
          </template>

          <!-- ========
          Menu
          ======= -->
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
                  mdi-menu
                </v-icon>
              </template>
              <v-card>
                <v-list dense nav>
                  <v-list-item
                    link
                    @click="
                      dialogEditando = true;
                      editando = {
                        ...item,
                        data_ini_a: item.data_ini.datepicker,
                        data_fim_a: item.data_fim.datepicker,
                      };
                      xls = null;
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-file-document-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="Delete(item.id)">
                    <v-list-item-icon>
                      <v-icon color="error">mdi-close</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    link
                    @click="
                      dialogCopiando = true;
                      novoCadastro = {
                        ...item,
                        data_ini: item.data_ini.datepicker,
                        data_fim: item.data_fim.datepicker,
                      };
                      xls = null;
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="orange">mdi-content-copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Copiar</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </template>

          <!-- ========
          Menu
          ======= -->
        </v-data-table>

        <!-- =============================
              DIAGOLO PARA CADASTRAR DOS DADOS
              =========================== -->
        <v-dialog v-model="dialog" scrollable max-width="90%" width="90%">
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Nova Campanha
            </v-card-title>

            <v-card-text class="mt-15">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.nome"
                      label="Nome da Campanha Admin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.nomeapp"
                      label="Nome da Campanha APP"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      class="text-capitalize"
                      :items="['raia', 'drogasil']"
                      label="Bandeira"
                      v-model="novoCadastro.bandeira"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      truncate-length="4"
                      outlined
                      dense
                      prepend-icon="mdi-file-upload"
                      accept="application/pdf"
                      clearable
                      label="PDF"
                      v-model="pdf"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      truncate-length="4"
                      outlined
                      dense
                      @change="novoCadastroXls"
                      prepend-icon="upload_file"
                      small-chips
                      clearable
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      chips
                      label="importar lojas"
                      v-model="xls"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="cluster"
                      label="Cluster"
                      item-text="nome"
                      item-value="id_cluster"
                      clearable
                      @click:clear="filtrandoNovoCadastro()"
                      v-model="novoCadastro.Cluster"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Estados"
                      label="Estado"
                      item-text="nome"
                      item-value="sigla"
                      clearable
                      v-model="novoCadastro.estado"
                      @click:clear="filtrandoNovoCadastro()"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Cidades"
                      label="Cidades"
                      item-text="cidade"
                      item-value="cidade"
                      clearable
                      v-model="novoCadastro.cidade"
                      @click:clear="filtrandoNovoCadastro()"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="novoCadastro.id_loja"
                      :items="lojas"
                      item-text="label"
                      item-value="id"
                      attach
                      label="Lojas"
                      multiple
                    >
                      <!-- <template v-slot:prepend-item>
                        <v-list-item ripple @click="novoCadastroSelectAll">
                          <v-list-item-action>
                            <v-icon>{{ iconSelectAllNovoCadastro }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              >Selecionar todos</v-list-item-title>

                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template> -->

                      <!-- <template v-slot:selection="{ item: i }">
                        <v-chip close @click:close="removeloja(i,false)">
                          <span>{{ i.label }}</span>
                        </v-chip>

                      </template> -->

                      <template v-slot:selection="{ item: i, index }">
                        <v-chip
                          close
                          @click:close="removeloja(i, false)"
                          v-if="index < 3"
                        >
                          <span>{{ i.label }}</span>
                        </v-chip>

                        <span v-if="index === 3" class="grey--text caption"
                          >(+ outras
                          {{ novoCadastro.id_loja.length - 3 }} lojas)</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="2" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="novoCadastro.id_peca"
                      :items="pecas"
                      item-text="nome"
                      item-value="id"
                      attach
                      label="Peças"
                      multiple
                    >
                      <template v-slot:selection="{ item: i, index }">
                        <v-chip
                          close
                          @click:close="removepeca(i, false)"
                          v-if="index < 3"
                        >
                          <span>{{ i.nome }}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text caption"
                          >(+ outras
                          {{ novoCadastro.id_peca.length - 3 }} peças)</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.observacao"
                      label="Observação"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      label="Data inicial"
                      v-model="novoCadastro.data_ini"
                      @change="novoCadastroDataVerify"
                      outlined
                      dense
                      type="date"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      label="Data Final"
                      :allowed-dates="novoCadastroAllowedDates"
                      @change="novoCadastroDataVerify"
                      v-model="novoCadastro.data_fim"
                      outlined
                      dense
                      type="date"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="baixarLojasXls" color="primary">
                Baixar Lojas
              </v-btn>
              <v-btn
                color="danger"
                @click="(novoCadastro = {}), (lojas = [...lojasOriginais])"
              >
                <v-icon>mdi-refresh</v-icon>Limpar</v-btn
              >

              <v-btn color="error" @click="dialog = false">
                <v-icon>mdi-close</v-icon>Cancelar</v-btn
              >
              <v-btn class="cyan" @click="CriarNovo(novoCadastro)"
                ><v-icon>mdi-chevron-right</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- =============================
              DIAGOLO PARA EDITAR DOS DADOS
              =========================== -->
        <v-dialog v-model="dialogEditando" scrollable max-width="90%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Editando Campanha {{ editando.nome }}
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>
            <v-divider></v-divider>

            <v-card-text>

              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      class="text-capitalize"
                      :items="['raia', 'drogasil']"
                      label="Bandeira"
                      v-model="editando.bandeira"
                      @change="filtrandoEditandoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      outlined
                      dense
                      prepend-icon="mdi-file-upload"
                      accept="application/pdf"
                      clearable
                      label="PDF"
                      @change="editandoPDF"
                      v-model="pdf"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      outlined
                      dense
                      @change="editandoXls"
                      prepend-icon="upload_file"
                      small-chips
                      clearable
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      chips
                      label="importar lojas"
                      v-model="xls"
                    >
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="cluster"
                      label="Cluster"
                      item-text="nome"
                      item-value="id_cluster"
                      clearable
                      @click:clear="filtrandoEditandoCadastro()"
                      v-model="editando.Cluster"
                      @change="filtrandoEditandoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Estados"
                      label="Estado"
                      item-text="nome"
                      item-value="sigla"
                      clearable
                      v-model="editando.estado"
                      @click:clear="filtrandoEditandoCadastro()"
                      @change="filtrandoEditandoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Cidades"
                      label="Cidades"
                      item-text="cidade"
                      item-value="cidade"
                      clearable
                      v-model="editando.cidade"
                      @click:clear="filtrandoEditandoCadastro()"
                      @change="filtrandoEditandoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="editando.id_loja"
                      :items="lojas"
                      item-text="label"
                      item-value="id"
                      attach
                      label="Lojas"
                      multiple
                    >
                      <!-- <template v-slot:prepend-item>
                        <v-list-item ripple @click="editandoSelectAll()">
                          <v-list-item-action>
                            <v-icon>{{ iconSelectAllEditando }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              >Selecionar todos</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template> -->

                      <template v-slot:selection="{ item: i }">
                        <v-chip close @click:close="removeloja(i, true)">
                          <span>{{ i.label }} </span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="editando.id_peca"
                      :items="pecas"
                      item-text="nome"
                      item-value="id"
                      attach
                      label="Peças"
                      multiple
                    >
                      <template v-slot:selection="{ item: i, index }">
                        <v-chip
                          close
                          @click:close="removepeca(i, true)"
                          v-if="index < 3"
                        >
                          <span>{{ i.nome }}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text caption"
                          >(+ outras
                          {{ editando.id_peca.length - 3 }} peças)</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="editando.observacao"
                      label="Observação"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      label="Data inicial"
                      v-model="editando.data_ini_a"
                      @change="editandoDataVerify"
                      outlined
                      dense
                      type="date"
                      disabled
                    ></v-text-field>
                    <v-text-field
                      label="Data Final"
                      :allowed-dates="editandoAllowedDates"
                      @change="editandoDataVerify"
                      v-model="editando.data_fim_a"
                      outlined
                      dense
                      type="date"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="baixarLojasXls" color="primary">
                Baixar a Loja
              </v-btn>
                 <v-btn
                color="danger"
                @click="(editando = {}), (lojas = [...lojasOriginais])"
              >
                <v-icon>mdi-refresh</v-icon>Limpar</v-btn
              >
              <v-btn color="error" @click="dialogEditando = false">
                <v-icon>mdi-close</v-icon>Cancelar</v-btn
              >
              <v-btn class="cyan" @click="Editar(editando)"
                ><v-icon>mdi-chevron-right</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- =============================
              DIAGOLO PARA COPIA DOS DADOS
              =========================== -->
        <v-dialog v-model="dialogCopiando" scrollable max-width="90%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              COPIAR CAMPANHA
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.nome"
                      label="Nome da Campanha Admin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.nomeapp"
                      label="Nome da Campanha APP"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      class="text-capitalize"
                      :items="['raia', 'drogasil']"
                      label="Bandeira"
                      v-model="novoCadastro.bandeira"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      truncate-length="4"
                      outlined
                      dense
                      prepend-icon="mdi-file-upload"
                      accept="application/pdf"
                      clearable
                      label="PDF"
                      @change="copiandoPDF"
                      v-model="pdf"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-file-input
                      outlined
                      dense
                      @change="novoCadastroXls"
                      prepend-icon="upload_file"
                      small-chips
                      clearable
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      chips
                      label="importar lojas"
                      v-model="xls"
                    >
                    </v-file-input>
                  </v-col>

                    <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="cluster"
                      label="Cluster"
                      item-text="nome"
                      item-value="id_cluster"
                      clearable
                      @click:clear="filtrandoNovoCadastro()"
                      v-model="novoCadastro.Cluster"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Estados"
                      label="Estado"
                      item-text="nome"
                      item-value="sigla"
                      clearable
                      v-model="novoCadastro.estado"
                      @click:clear="filtrandoNovoCadastro()"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-1">
                    <v-autocomplete
                      multiple
                      outlined
                      dense
                      class="text-capitalize"
                      :items="Cidades"
                      label="Cidades"
                      item-text="cidade"
                      item-value="cidade"
                      clearable
                      v-model="novoCadastro.cidade"
                      @click:clear="filtrandoNovoCadastro()"
                      @change="filtrandoNovoCadastro()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="novoCadastro.id_loja"
                      :items="lojas"
                      item-text="label"
                      item-value="id"
                      attach
                      label="Lojas"
                      multiple
                    >
                      <!-- <template v-slot:prepend-item>
                        <v-list-item ripple @click="novoCadastroSelectAll">
                          <v-list-item-action>
                            <v-icon>{{ iconSelectAllNovoCadastro }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              >Selecionar todos</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template> -->
                      <template v-slot:selection="{ item: i }">
                        <v-chip close @click:close="removeloja(i, false)">
                          <span>{{ i.label }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="2" sm="6" md="6" lg="6" xl="6" class="px-1">
                    <v-autocomplete
                      outlined
                      dense
                      v-model="novoCadastro.id_peca"
                      :items="pecas"
                      item-text="nome"
                      item-value="id"
                      attach
                      label="Peças"
                      multiple
                    >
                      <template v-slot:selection="{ item: i, index }">
                        <v-chip
                          close
                          @click:close="removepeca(i, false)"
                          v-if="index < 3"
                        >
                          <span>{{ i.nome }}</span>
                        </v-chip>
                        <span v-if="index === 3" class="grey--text caption"
                          >(+ outras
                          {{ novoCadastro.id_peca.length - 3 }} peças)</span
                        >
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      outlined
                      dense
                      v-model="novoCadastro.observacao"
                      label="Observação"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-1">
                    <v-text-field
                      label="Data inicial"
                      v-model="novoCadastro.data_ini"
                      @change="novoCadastroDataVerify"
                      outlined
                      dense
                      type="date"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      label="Data Final"
                      :allowed-dates="novoCadastroAllowedDates"
                      @change="novoCadastroDataVerify"
                      v-model="novoCadastro.data_fim"
                      outlined
                      dense
                      type="date"
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="baixarLojasXls" color="primary">
                Baixar Lojas
              </v-btn>
              <v-btn color="danger" @click="lojas = [...lojasOriginais]">
                <v-icon>mdi-close</v-icon>Limpar</v-btn
              >
              <v-btn color="error" @click="dialogCopiando = false">
                <v-icon>mdi-close</v-icon>Cancelar</v-btn
              >
              <v-btn class="cyan" @click="CriarNovo(novoCadastro, true)"
                ><v-icon>mdi-chevron-right</v-icon>Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>


<script>
import axios from "axios";
import XLSX from "xlsx";
import uf from "../../src/json/estadosBrasileiros.json";

export default {
  data: () => ({
    campanhasFiltradas: [],
    headers: [
      { text: "Ação", value: "acao", align: "center" },
      { text: "Mês/Ano", value: "mesAno", sortable: true, align: "center" },
      {
        text: "Nome da Campanha Admin",
        value: "nome",
        sortable: false,
        align: "center",
      },
      {
        text: "Nome da Campanha APP",
        value: "nomeapp",
        sortable: false,
        align: "center",
      },
      { text: "Período", value: "periodo", sortable: true, align: "center" },
      { text: "Bandeira", value: "bandeira", align: "center" },
      { text: "Arquivo da Campanha", value: "pdf", align: "center" },
      { text: "Observação", value: "observacao", align: "center" },
      { text: "Peças", value: "peca", align: "center" },
      { text: "Filias", value: "filias", align: "center" },
    ],
    xls: null,
    pdf: null,
    dialog: false,
    snackbar: false,
    snackbarText: "",
    overlay: true,
    pecas: [],
    pecasoriginal: [],
    lojasOriginais: [],
    campanhas: [],
    lojas: [],
    cluster: [],
    filtro: {},
    teste: [],
    novoCadastro: {},
    itemsPerPageArray: [1, 5, 10, 15],
    search: null,
    search2: null,
    search3: null,
    search4: null,
    pecaW: null,
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: "nome",
    numberOfPages: 1,
    items: [],
    items2: [],
    items3: [],
    items4: [],
    editando: {},
    dialogEditando: false,
    dialogCopiando: false,
    carregando: false,
    passo: 1,
    Estados: uf,
    Cidades: [],
  }),

  computed: {
    novoCadastroSelectedAll() {
      return (
        this.novoCadastro &&
        this.novoCadastro.id_loja &&
        Array.isArray(this.novoCadastro.id_loja) &&
        this.novoCadastro.id_loja.length == this.lojas.length
      );
    },
    novoCadastroSelectedSome() {
      return (
        this.novoCadastro &&
        this.novoCadastro.id_loja &&
        this.novoCadastro.id_loja.length > 0
      );
    },
    iconSelectAllNovoCadastro() {
      if (this.novoCadastroSelectedAll) return "mdi-close-box";
      if (this.novoCadastroSelectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    editandoSelectedAll() {

      return (
        this.editando &&
        this.editando.id_loja &&
        Array.isArray(this.editando.id_loja) &&
        this.editando.id_loja.length == this.lojas.length
      );
    },
    editandoSelectedSome() {
      return (
        this.editando &&
        this.editando.id_loja &&
        this.editando.id_loja.length > 0
      );
    },
    iconSelectAllEditando() {

      if (this.editandoSelectedAll) return "mdi-close-box";
      if (this.editandoSelectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    filtrocluster() {
      if (this.novoCadastro.Cluster) {
        this.lojas = [
          ...this.lojasOriginais.filter((v) =>
            this.novoCadastro.Cluster.includes(v.id_cluster)
          ),
        ];

        if (this.novoCadastro.bandeira) {
          this.lojas = this.lojas.filter((v) => {
            return (
              v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
            );
          });
        }

        this.selectTodasLojasNovoCadastro(); // marca todas lojas
      }

      if (this.novoCadastro.Cluster) {
        //verificar se cluster não esta vazio
        if (Object.values(this.novoCadastro.Cluster).length < 1) {
          this.lojas = [...this.lojasOriginais];

          delete this.novoCadastro.Cluster;

          if (this.novoCadastro.bandeira) {
            this.lojas = [
              ...this.lojas.filter((v) => {
                return (
                  v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
                );
              }),
            ];
          }

          this.selectTodasLojasNovoCadastro();
        }
      }
    },

    filtroestado() {
      if (this.novoCadastro.estado) {
        this.lojas = [
          ...this.lojasOriginais.filter((v) =>
            this.novoCadastro.estado.includes(v.estado)
          ),
        ];

        if (this.novoCadastro.Cluster) {
          this.lojas = [
            ...this.lojas.filter((v) =>
              this.novoCadastro.Cluster.includes(v.id_cluster)
            ),
          ];
        }

        if (this.novoCadastro.bandeira) {
          this.lojas = this.lojas.filter((v) => {
            return (
              v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
            );
          });
        }

        this.selectTodasLojasNovoCadastro(); // marca todas lojas
      }

      if (this.novoCadastro.estado) {
        //verificar se cluster não esta vazio
        if (Object.values(this.novoCadastro.estado).length < 1) {
          this.lojas = [...this.lojasOriginais];

          delete this.novoCadastro.estado;

          if (this.novoCadastro.Cluster) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.novoCadastro.Cluster.includes(v.id_cluster)
              ),
            ];
          }

          if (this.novoCadastro.bandeira) {
            this.lojas = this.lojas.filter((v) => {
              return (
                v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
              );
            });
          }
        }

        this.selectTodasLojasNovoCadastro();
      }
    },

    filtrocidade() {
      if (this.novoCadastro.cidade) {
        this.lojas = [
          ...this.lojasOriginais.filter((v) =>
            this.novoCadastro.cidade.includes(v.cidade)
          ),
        ];

        if (this.novoCadastro.Cluster) {
          this.lojas = [
            ...this.lojas.filter((v) =>
              this.novoCadastro.Cluster.includes(v.id_cluster)
            ),
          ];
        }

        if (this.novoCadastro.estado) {
          this.lojas = [
            ...this.lojas.filter((v) =>
              this.novoCadastro.estado.includes(v.estado)
            ),
          ];
        }

        if (this.novoCadastro.bandeira) {
          this.lojas = this.lojas.filter((v) => {
            return (
              v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
            );
          });
        }

        this.selectTodasLojasNovoCadastro(); // marca todas lojas
      }

      if (this.novoCadastro.cidade) {

        if (Object.values(this.novoCadastro.cidade).length < 1) {  //verificar se cluster não esta vazio
          this.lojas = [...this.lojasOriginais];
          delete this.novoCadastro.cidade;

          if (this.novoCadastro.Cluster) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.novoCadastro.Cluster.includes(v.id_cluster)
              ),
            ];
          }

          if (this.novoCadastro.bandeira) {
            this.lojas = this.lojas.filter((v) => {
              return (
                v.bandeira == String(this.novoCadastro.bandeira).toUpperCase()
              );
            });
          }

          if (this.novoCadastro.estado) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.novoCadastro.estado.includes(v.estado)
              ),
            ];
          }
        }

        this.selectTodasLojasNovoCadastro();
      }
    },

    filtrandoNovoCadastro() {
      this.overlay = true;

      if (this.novoCadastro.bandeira) {
        this.lojas = this.lojasOriginais.filter((v) => {
          return v.bandeira == String(this.novoCadastro.bandeira).toUpperCase();
        });
      }

      setTimeout(() => {
        this.filtrocluster();

        this.filtroestado();

        this.filtrocidade();

        // this.novoCadastroSelectAll() // marca todas lojas

        this.overlay = false;
      }, 500);

      if (this.novoCadastro.bandeira) {
        this.lojas = this.lojas.filter((v) => {
          return v.bandeira == String(this.novoCadastro.bandeira).toUpperCase();
        });
      }
    },


   filtroclusterEditando(){
        if (this.editando.Cluster) {
          this.lojas = [
            ...this.lojasOriginais.filter((v) =>
              this.editando.Cluster.includes(v.id_cluster)
            ),
          ];

          if (this.editando.bandeira) {
            this.lojas = this.lojas.filter((v) => {
              return v.bandeira == String(this.editando.bandeira).toUpperCase();
            });
          }

          this.selectTodasLojasEditando(); // marca todas lojas
        }

        if (this.editando.Cluster) {
          //verificar se cluster não esta vazio
          if (Object.values(this.editando.Cluster).length < 1) {
            this.lojas = [...this.lojasOriginais];

            delete this.editando.Cluster;

            if (this.editando.bandeira) {
              this.lojas = [
                ...this.lojas.filter((v) => {
                  return (
                    v.bandeira == String(this.editando.bandeira).toUpperCase()
                  );
                }),
              ];
            }

            this.selectTodasLojasEditando();
          }
        }
    },

    filtroestadoEditando(){
        if (this.editando.estado) {
           this.lojas = [
            ...this.lojasOriginais.filter((v) =>
              this.editando.estado.includes(v.estado)
            ),
          ];

          if (this.editando.Cluster) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.editando.Cluster.includes(v.id_cluster)
              ),
            ];
          }

          if (this.editando.bandeira) {
            this.lojas = this.lojas.filter((v) => {
              return v.bandeira == String(this.editando.bandeira).toUpperCase();
            });
          }

          this.selectTodasLojasEditando();  // marca todas lojas
        }

        if (this.editando.estado) {
          //verificar se cluster não esta vazio
          if (Object.values(this.editando.estado).length < 1) {
            this.lojas = [...this.lojasOriginais];

            delete this.editando.estado;

            if (this.editando.Cluster) {
              this.lojas = [
                ...this.lojas.filter((v) =>
                  this.editando.Cluster.includes(v.id_cluster)
                ),
              ];
            }

            if (this.editando.bandeira) {
              this.lojas = this.lojas.filter((v) => {
                return (
                  v.bandeira == String(this.editando.bandeira).toUpperCase()
                );
              });
            }
          }

          this.selectTodasLojasEditando();
        }
    },

    filtrocidadeEditando(){

        if (this.editando.cidade) {
          this.lojas = [
            ...this.lojasOriginais.filter((v) =>
              this.editando.cidade.includes(v.cidade)
            ),
          ];

          if (this.editando.Cluster) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.editando.Cluster.includes(v.id_cluster)
              ),
            ];
          }

          if (this.editando.estado) {
            this.lojas = [
              ...this.lojas.filter((v) =>
                this.editando.estado.includes(v.estado)
              ),
            ];
          }

          if (this.editando.bandeira) {
            this.lojas = this.lojas.filter((v) => {
              return v.bandeira == String(this.editando.bandeira).toUpperCase();
            });
          }

          this.selectTodasLojasEditando(); // marca todas lojas
        }

        if (this.editando.cidade) {
          //verificar se cluster não esta vazio
          if (Object.values(this.editando.cidade).length < 1) {
            this.lojas = [...this.lojasOriginais];
            delete this.editando.cidade;

            if (this.editando.Cluster) {
              this.lojas = [
                ...this.lojas.filter((v) =>
                  this.editando.Cluster.includes(v.id_cluster)
                ),
              ];
            }

            if (this.editando.bandeira) {
              this.lojas = this.lojas.filter((v) => {
                return (
                  v.bandeira == String(this.editando.bandeira).toUpperCase()
                );
              });
            }

            if (this.editando.estado) {
              this.lojas = [
                ...this.lojas.filter((v) =>
                  this.editando.estado.includes(v.estado)
                ),
              ];
            }
          }

          this.selectTodasLojasEditando();
        }
    },

    filtrandoEditandoCadastro() {
      this.overlay = true;

      if (this.editando.bandeira) {
        this.lojas = this.lojasOriginais.filter((v) => {
          return v.bandeira == String(this.editando.bandeira).toUpperCase();
        });
      }

      setTimeout(() => {
          this.filtroclusterEditando()
          this.filtroestadoEditando()
          this.filtrocidadeEditando()




        this.overlay = false;
      }, 500);

      if (this.editando.bandeira) {
        this.lojas = this.lojas.filter((v) => {
          return v.bandeira == String(this.editando.bandeira).toUpperCase();
        });
      }
    },

    selectAll() {
      this.novoCadastro.id_peca = [...this.pecas];
    },

     selectTodasLojasNovoCadastro() {
      this.novoCadastro.id_loja = [...this.lojas.map((val) => val.id)];
    },

     selectTodasLojasEditando() {
      this.editando.id_loja = [...this.lojas.map((val) => val.id)];
    },

    removepeca(v, acao) {
      var removeIndex = this.pecas.findIndex((item) => item.id === v.id);

      if (!acao) {
        this.pecas.splice(removeIndex, 1);

        for (let id of this.novoCadastro.id_peca) {
          if (id == v.id) {
            let indexnovocadastropeca = this.novoCadastro.id_peca.findIndex(
              (x) => x == id
            );
            this.novoCadastro.id_peca.splice(indexnovocadastropeca, 1);
          }
        }
        this.pecas = [...this.pecasoriginal]; // atribuir valores originais
      } else {
        this.pecas.splice(removeIndex, 1);

        for (let id of this.editando.id_peca) {
          if (id == v.id) {
            let indexeditandopeca = this.editando.id_peca.findIndex(
              (x) => x == id
            );
            this.editando.id_peca.splice(indexeditandopeca, 1);
          }
        }

        this.pecas = [...this.pecasoriginal]; // atribuir valores originais
      }
    },
    removeloja(v, acao) {
      const lojas = [...this.lojas];
      var removeIndex = this.lojas.findIndex((item) => item.id === v.id);

      if (!acao) {
        this.lojas.splice(removeIndex, 1); // remove ou close a loja da autocomplete

        for (let id of this.novoCadastro.id_loja) {
          if (id == v.id) {
            let indexnovocadastroloja = this.novoCadastro.id_loja.findIndex(
              (x) => x == id
            );
            this.novoCadastro.id_loja.splice(indexnovocadastroloja, 1);
          }
        }
        this.lojas = [...lojas]; // atribuir valores  originais
      } else {
        this.lojas.splice(removeIndex, 1);

        for (let id of this.editando.id_loja) {
          if (id == v.id) {
            let indexeditandoloja = this.editando.id_loja.findIndex(
              (x) => x == id
            );
            this.editando.id_loja.splice(indexeditandoloja, 1);
          }
        }

        this.lojas = [...lojas]; // atribuir valores  originais
      }
    },

    limpar() {
      this.filtro = [];
    },
    novoCadastroSelectAll() {
      if (!this.novoCadastroSelectedAll) {
        this.novoCadastro.id_loja = [...this.lojas.map((val) => val.id)];
      } else {
        this.novoCadastro.id_loja = [];
      }
    },
    editandoSelectAll() {


      if (!this.editandoSelectedAll) {
        this.editando.id_loja = [...this.lojas.map((val) => val.id)];
      } else  {
        this.editando.id_loja = [];
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    allDates() {
      const dates = new Set();
      this.campanhas.forEach((val) =>
        dates.add(`${val.mes.toString().padStart(2, "0")}/${val.ano}`)
      );
      return Array.from(dates);
    },
    stringToDate(data) {
      let date = new Date(data);
      return {
        dia: date.getUTCDate().toString().padStart(2, "0"),
        mes: (date.getUTCMonth() + 1).toString().padStart(2, "0"),
        ano: date.getUTCFullYear(),
        datepicker: `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`,
        string: `${date.getUTCDate().toString().padStart(2, "0")}/${(
          date.getUTCMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getUTCFullYear()}`,
        date,
      };
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    async xlstoidloja(xls) {
      const results = (await this.readerData(xls, 0)).results;

      if (results.length == 0) {
        this.snackbar = true;
        this.snackbarText = `não foram encontrados nenhum registro`;
        return [];
      }

      if (!(results[0].id_java != null) && !(results[0]["id java"] != null)) {
        this.snackbar = true;
        this.snackbarText = `È necessário ter o campo id java ou id_java`;
        return [];
      }
      const id_loja = results.map((val) => val.id_java || val["id java"]);
      return id_loja;
    },
    async novoCadastroXls(xls) {

      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);

        const lojasEncontradas = await id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );

        const lojasNaoEncontradas = await id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );

        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }

        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.novoCadastro.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
    async editandoXls(xls) {
      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);
        const lojasEncontradas = id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );
        const lojasNaoEncontradas = id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );
        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }
        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.editando.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
    nomePecas() {
      return this.pecas.map((val) => val.nome);
    },
    nomeLojas() {
      return this.lojas.map((val) => val.descricao);
    },
    pecaIDtoName(id) {
      return this.pecas.find((val) => val.id == id).nome;
    },
    lojaIDtoName(id) {
      return this.lojas.find((val) => val.id == id).descricao;
    },
    close() {
      this.dialog = false;
    },
    changeCurrentItens(value) {
      this.teste = value;
    },
    exportar() {
      const data = this.campanhasFiltradas.map((val) => ({
        "Mês/Ano": `${val.mes.toString().padStart(2, "0")}/${val.ano}`,
        "Nome Da Campanha Admin": val.nome,
        "Nome Da Campanha App": val.nomeapp,
        Período: `${val.data_ini.string} à ${val.data_fim.string}`,
        Observação: val.observacao,
        Bandeira: val.bandeira,
        PDF: val.pdf
          ? {
              f: `HYPERLINK("${this.$assinarUrl(val.pdf)}","PDF ${val.nome}")`,
            }
          : "",
        lojas: val.id_loja
          .map((val) => this.lojas.find((loja) => loja.id == val).descricao)
          .join(", "),
        pecas: val.id_peca
          .map((val) => this.pecas.find((peca) => peca.id == val).nome)
          .join(", "),
      }));
      const ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Lista de Campanhas");
      XLSX.writeFile(wb, `lista_de_campanhas_${new Date().getTime()}.xlsx`);
    },
    baixarLojasXls() {
      const dadosLoja = this.lojas.map((val) => ({
        "id java": val.id_java,
        bandeira: val.bandeira,
        regiao: val.regiao,
        estado: val.estado,
        cidade: val.cidade,
        perfil: val.perfil,
        cluster: val.cluster,
        tamanho: val.tamanho,
        "check out": val.check_out,
      }));
      const ws = XLSX.utils.json_to_sheet(dadosLoja);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Lista de Campanhas");
      XLSX.writeFile(wb, `lista_de_lojas_${new Date().getTime()}.xlsx`);
    },
    changePages(val) {
      this.numberOfPages = val;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    filter() {

      this.campanhasFiltradas = this.campanhas;
      if (this.filtro.bandeira)
        this.campanhasFiltradas = this.campanhasFiltradas.filter(
          (val) => val.bandeira == this.filtro.bandeira
        );

      if (this.filtro.data_ini_prev) {
        const [dia, mes, ano] = this.filtro.data_ini_prev.split("/");
        const data = new Date(Date.UTC(ano, mes - 1, dia));

        this.campanhasFiltradas = this.campanhasFiltradas.filter(
          (val) => val.data_ini.date >= data
        );
      }

      if (this.filtro.data_fim_prev) {
        const [dia, mes, ano] = this.filtro.data_fim_prev.split("/");
        const data = new Date(Date.UTC(ano, mes - 1, dia));

        this.campanhasFiltradas = this.campanhasFiltradas.filter(
          (val) => val.data_fim.date <= data
        );
      }

      if (this.filtro.campanha)
        this.campanhasFiltradas = this.campanhasFiltradas.filter((val) =>
          val.nome.toLowerCase().includes(this.filtro.campanha.toLowerCase())
        );
      if (this.filtro.campanhaapp)
        this.campanhasFiltradas = this.campanhasFiltradas.filter((val) =>
          val.nomeapp != null
            ? val.nomeapp
                .toLowerCase()
                .includes(this.filtro.campanhaapp.toLowerCase())
            : false
        );

      if (this.filtro.peca) {
        const pecasIds = this.pecas
          .filter((val) => val.nome.toLowerCase().includes(this.filtro.peca))
          .map((val) => val.id);
        this.campanhasFiltradas = this.campanhasFiltradas.filter((val) =>
          val.id_peca.find((id_peca) => pecasIds.includes(id_peca))
        );
      }

      if (this.filtro.filial) {
        const filialIds = this.lojas
          .filter((val) =>
            val.descricao.toLowerCase().includes(this.filtro.filial)
          )
          .map((val) => val.id);
        this.campanhasFiltradas = this.campanhasFiltradas.filter((val) =>
          val.id_loja.find((id_loja) => filialIds.includes(id_loja))
        );
      }
      if (this.filtro.dates && this.filtro.dates.length > 0) {
        this.campanhasFiltradas = this.campanhasFiltradas.filter((val) =>
          this.filtro.dates.includes(
            `${val.mes.toString().padStart(2, "0")}/${val.ano}`
          )
        );
      }

      this.dialogEditando = true;
      this.dialogEditando = false;
    },
    querySelections(v) {
      this.items = [
        ...this.campanhas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections2(v) {
      this.items2 = [
        ...this.pecas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections3(v) {
      this.items3 = [
        ...this.lojas
          .map((val) => val.descricao)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections4(v) {
      this.items4 = [
        ...this.campanhas
          .map((val) => (val.nomeapp != null ? val.nomeapp : ""))
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    novoCadastroAllowedDates(val) {
      if (this.novoCadastro.data_ini) return val > this.novoCadastro.data_ini;
      return true;
    },
    editandoAllowedDates(val) {
      if (this.editando.data_ini_a) return val > this.editando.data_ini_a;
      return true;
    },
    async CriarNovo(novoCadastro, copiando) {
      if (copiando) {
        try {
          //const regexAll = /[^\\]*\.(\w+)$/;
          if (
            novoCadastro.bandeira &&
            novoCadastro.data_fim &&
            novoCadastro.data_ini &&
            novoCadastro.nome
          ) {
            this.dialogCopiando = false;
            this.overlay = true;
            if (this.pdf instanceof File) {
              const link = (
                await axios.post(
                  `${this.$SERVER_URL}adm/sync/pdf`,
                  {},
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods":
                        "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                      "Access-Control-Allow-Headers":
                        "Origin, Content-Type, X-Auth-Token",
                    },
                  }
                )
              ).data;
              const pdf = this.pdf;
              await axios.put(link.uploadUrl, pdf, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                  "Content-Type": pdf.type,
                },
              });
              this.novoCadastro.pdf = link.downloadUrl;
            } else {
              this.novoCadastro.pdf = "";
            }

            novoCadastro.userid = localStorage.getItem("userid");
            this.campanhas = (
              await axios.post(
                `${this.$SERVER_URL}adm/campanha`,
                novoCadastro,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Origin, Content-Type, X-Auth-Token",
                  },
                }
              )
            ).data;




            Promise.all(this.campanhas)


           this.snackbar = true;
            this.snackbarText = "Operação feita com sucesso";

            //this.filter();
            this.novoCadastro = {};
            this.pdf = {};
            this.overlay = false;
            this.$router.go();
          } else {
            this.snackbar = true;
            this.snackbarText = "É necessáro preencher todos os campos";
          }
        } catch (error) {
          this.snackbar = true;
          this.snackbarText = "Algum erro aconteceu, tente novamente";
        }
      } else {
        try {
          if (
            novoCadastro.bandeira &&
            novoCadastro.data_fim &&
            novoCadastro.data_ini &&
            novoCadastro.nome
          ) {
            this.dialog = false;
            this.overlay = true;

            if (this.pdf instanceof File) {
              const link = (
                await axios.post(
                  `${this.$SERVER_URL}adm/sync/pdf`,
                  {},
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods":
                        "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                      "Access-Control-Allow-Headers":
                        "Origin, Content-Type, X-Auth-Token",
                    },
                  }
                )
              ).data;
              const pdf = this.pdf;
              await axios.put(link.uploadUrl, pdf, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                  "Content-Type": pdf.type,
                },
              });
              this.novoCadastro.pdf = link.downloadUrl;
            } else {
              this.novoCadastro.pdf = "";
            }
            novoCadastro.userid = localStorage.getItem("userid");

            this.campanhas = (
              await axios.post(
                `${this.$SERVER_URL}adm/campanha`,
                novoCadastro,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Origin, Content-Type, X-Auth-Token",
                  },
                }
              )
            ).data;


             Promise.all(this.campanhas)



            this.snackbar = true;
            this.snackbarText = "Operação feita com sucesso";
            //this.filter();
            this.novoCadastro = {};
            this.pdf = {};
            this.overlay = false;
            this.$router.go();
          } else {
            this.snackbar = true;
            this.snackbarText = "É necessáro preencher todos os campos";
          }
        } catch (error) {

          this.snackbar = true;
          this.snackbarText = "Algum erro aconteceu, tente novamente";
        }
      }
    },
    async editandoPDF(pdf) {
      this.pdf = pdf;
    },
    async copiandoPDF(pdf) {
      this.pdf = pdf;
    },
    async Editar(editando) {
      try {
        this.overlay = true;
        this.dialogEditando = false;

        if (this.pdf instanceof File) {
          const link = (
            await axios.post(
              `${this.$SERVER_URL}adm/sync/pdf`,
              {},
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                },
              }
            )
          ).data;
          const pdf = this.pdf;

          await axios.put(link.uploadUrl, pdf, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": pdf.type,
            },
          });
          this.pdf = link.downloadUrl;
        }
        const pdf = this.pdf;
        const {
          bandeira,
          data_fim_a,
          data_ini_a,
          id,
          id_loja,
          id_peca,
          observacao,
        } = editando;

        this.campanhas = (
          await axios.put(
            `${this.$SERVER_URL}adm/campanha/${id}`,
            {
              bandeira,
              data_fim: data_fim_a,
              data_ini: data_ini_a,
              id_loja,
              id_peca,
              observacao,
              pdf,
              userid: localStorage.getItem("userid"),
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;


        Promise.all(this.campanhas)

        this.snackbar = true;
        this.snackbarText = "Operação feita com sucesso";
        this.overlay = false;

        this.$router.go();
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = "Algum erro aconteceu, tente novamente";
        this.overlay = false;
      }
    },

    async Delete(id) {
      try {
        this.overlay = true;
        this.campanhas = (
          await axios.delete(
            `${this.$SERVER_URL}adm/campanha/${id}/${localStorage.getItem(
              "userid"
            )}`,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;
        this.campanhas = this.campanhas.map((val) => ({
          ...val,
          data_ini: this.stringToDate(val.data_ini),
          data_fim: this.stringToDate(val.data_fim),
          peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
          loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
        }));
        this.filter();

        this.snackbar = true;

        this.filter();
        this.snackbarText = "Operação feita com sucesso";
        this.overlay = false;
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = "Algum erro aconteceu, tente novamente";
        this.overlay = false;
      }
    },
    novoCadastroDataVerify() {
      if (
        this.novoCadastro.data_ini &&
        this.novoCadastro.data_fim &&
        this.novoCadastro.data_ini > this.novoCadastro.data_fim
      )
        this.novoCadastro.data_fim = null;
    },
    editandoDataVerify() {
      if (
        this.editando.data_ini_a &&
        this.editando.data_fim_a &&
        this.editando.data_ini_a > this.editando.data_fim_a
      )
        this.editando.data_fim = null;
    },
    async downloadItem(url) {
      url = this.$arquivoProtegido(url);
      window.open(url, "_blank");
      // var a = (
      //   await axios.get(url, {
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //       "Access-Control-Allow-Methods":
      //         "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      //       "Access-Control-Allow-Headers":
      //         "Origin, Content-Type, X-Auth-Token",
      //     },
          
      //     responseType: "blob",
      //     withCredentials: true,
      //   })
      // ).data;

      // const blob = new Blob([a], { type: "application/pdf" });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = label;
      // link.click();
      // URL.revokeObjectURL(link.href);
    },
  },
  watch: {


    search(val) {
      val && val !== this.filtro.campanha && this.querySelections(val);
      this.filtro.campanha = val;
      this.filter();
    },
    search2(val) {
      val && val !== this.filtro.peca && this.querySelections2(val);
      this.filtro.peca = val;
      this.filter();
    },
    search3(val) {
      val && val !== this.filtro.filial && this.querySelections3(val);
      this.filtro.filial = val;
      this.filter();
    },
    search4(val) {
      val && val !== this.filtro.campanhaapp && this.querySelections4(val);
      this.filtro.campanhaapp = val;

      this.filter();
    },
  },
  async mounted() {
    this.cluster = (
      await axios.get(`${this.$SERVER_URL}adm/cluster`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;

    this.lojas = (
      await axios.get(`${this.$SERVER_URL}adm/lojaCluster`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;
    this.lojas = this.lojas.map((val) => ({
      ...val,
      label: val.id_java +"-" + val.descricao,
    }));

    this.lojasOriginais = [...this.lojas];
    this.Cidades = [...this.lojas];

    this.pecas = (
      await axios.get(`${this.$SERVER_URL}adm/allPecas`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;

    this.pecasoriginal = [...this.pecas];
    this.campanhas = (
      await axios.get(`${this.$SERVER_URL}adm/campanha`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;

    this.campanhas = this.campanhas.map((val) => ({
      ...val,
      data_ini: this.stringToDate(val.data_ini),
      data_fim: this.stringToDate(val.data_fim),
      peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
      loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
    }));

    this.campanhasFiltradas = this.campanhas;

    this.$store.commit("importacaoPecas/pecasJaCadastradas", {
      pecas: this.pecas,
    });

    this.querySelections("");
    this.querySelections2("");
    this.querySelections3("");
    this.querySelections4("");
    this.overlay = false;
  },
};
</script>
<style>
/* .v-toolbar {
  height: 100% !important;
}
.v-toolbar__content {
  height: 100% !important;
} */

.v-chip.v-chip--no-color.theme--light.v-size--default.selecionados {
  background-color: none;
}

.checkbox-label {
  display: block;
}
</style>

